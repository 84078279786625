.titleBarUser {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.playerAvatar {
  margin-right: 20px;
}

.currentCardsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 30%;
  align-items: center;
}

.cardContainers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.TeamInfo {
  color: white;
}

.teamInfoSub {
  display: flex;
}

.teamInfoCoins {
  display: flex;
  align-items: center;
}

.coins {
  width: 30px;
  height: 30px;
}

.curse {
  background-color: #dba5eb;
  min-width: 200px;
  height: 100px;
  margin-top: 20px;
  margin-right: 5px;
  border-radius: 10px;
  padding: 5px;
  padding-top: 20px;
}

.curseContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
