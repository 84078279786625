/* Define the keyframes for the sway animation */
@keyframes sway {
    0%, 100% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(5deg);
    }
  }

  
  /* Include the sway animation from the previous example */
  .swaying-icon {
    animation: sway 2s ease-in-out infinite;
    display: inline-block;
    transform-origin: center;
  }
