.pages {
  max-width: "100vw";
}

.profilePaper {
  position: relative;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 56px;
  padding-bottom: 20px;
  padding-top: 50px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
}

.paperBottom {
  width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  z-index: 15;
  color: black;
  margin-top: 30px;
}

.settingsContainer {
  right: 0px;
  top: 0px;
  position: absolute;
}

.profileMain {
  width: clamp(100px, 100vw, 400px);
}

.profileMainContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profilePicture {
  width: inherit;
  display: flex;
  justify-content: center;
  display: flex;
  position: absolute;
  z-index: 10;
  top: 0;
  margin-top: 30px;
  color: black;
}

.avatarWithText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.profileItems {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nameDiv {
  height: 20%;
  margin-bottom: 10px;
}

.confetti-fade-enter {
  opacity: 0;
  transition: opacity 0.5s;
}

.confetti-fade-enter-active {
  opacity: 1;
}

.confetti-fade-exit {
  opacity: 1;
  transition: opacity 0.5s;
}

.confetti-fade-exit-active {
  opacity: 0;
}

.coinsContainer {
  height: 60px;
  width: 100%;
  background-color: #eebe56;
  font-size: 60px;
  align-items: center;
  opacity: 0.8;
  box-shadow: 5px 5px 0px #be8915;
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
}
.gemsContainer {
  height: 60px;
  width: 100%;
  background-color: #bf3de3;
  font-size: 60px;
  align-items: center;
  opacity: 0.8;
  box-shadow: 5px 5px 0px #8c2ca7;
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.coinsImg {
  height: 90px;
  width: auto;
}
.gemsImg {
  height: 90px;
  margin-left: 10px;
  width: auto;
}
.CurseCardCoinsImg {
  height: 35px;
  width: auto;
}

.coinsText {
  opacity: 1;
}

.buttonContainer {
  display: flex;
  justify-content: space-evenly;
}

.LoadingOverCard {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  z-index: 10;
  position: absolute;
  align-items: center;
  top: 0;
}

.ChallengeCard {
  position: relative;
  max-width: 100%;
  word-wrap: break-word;
}

.rewardContainer {
  display: flex;
}

.ChallengeCardContainer {
  position: relative;
}
.ChallengeCardContainerGM {
  position: relative;
  padding: 0px;
}

.loadingChallenge {
  opacity: 0.2;
}

.footerBuffer {
  height: 58px;
}

.curseCardContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 7px;
}

.curseImage {
  width: 90%;
}

.curseImageContainer {
  display: flex;
  justify-content: center;
}

.CurseCardCoinContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 10px 0px;
}

.curseCardContainer {
  position: relative;
}

.challengesLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* Leaderboard.css */
.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.leaderboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leaderboardPage {
  height: 100dvh;
}

.userCoinsDisplay {
  display: flex;
  justify-content: center;
}

.memberAvatarContainer {
  max-height: 300px;
  min-height: 70px;
  width: 100%;
  padding-top: 5px;
  overflow: auto;
}

.memberContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 40%;
}

.memberItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: calc((100% / 3) - 20px);
  width: 30px;
  margin-bottom: 10px;
}

.listAvatarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 8px;
  padding-right: 10px;
}

.usernameTextLeaderboard {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accountMenuIcon {
  z-index: 1;
  position: absolute;
  width: calc(90vw - 30px);
}

.subMenuWithIcon {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.vetoText {
  margin-right: 10px;
}

.gameOverScreen {
  font-family: "Press Start 2P", sans-serif;
  max-height: 80%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 80px;
}

.crownImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.crownImage {
  width: 180px;
}

.gmRequestContainer {
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.gmRequest {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  justify-content: space-between;
}

.gmProfileName {
  display: flex;
  align-items: center;
}

.gmLogo {
  width: 70px;
  float: right;
}

.gradient-text {
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
