body {
  margin: 0;
  padding: 0; /* Make sure there is no padding */
  font-family: 'EB Garamond',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/background8.svg");
  background-size: 600px;
  border-radius: 0px;
  background-color: #141414;
}
