.curseLineItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.curseItemImageContainer {
  width: 50px;
  height: 60px;
  margin: 5px;
}

.curseItemImage {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 4;
}

.lineItemText {
  margin-top: 5px;
  margin-left: 10px;
  align-items: flex-start;
  text-align: left;
  width: calc(100% - 80px - 70px);
  padding-left: 5px;
  padding-right: 5px;
}

.purchaseButtonImage {
  width: 15px;
}

.buttonContainer {
  height: 100%;
  margin-top: "17.5px";
}

.italics {
  font-style: italic;
}

.pulse {
  animation: pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.loadable {
  border-radius: 1.25rem;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
img {
  display: block;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}
