.joinGame {
  width: 90vw;
  height: 95vh;
  margin-top: 10px;
}

.title {
  text-align: center;
  padding-top: 30px;
}

.joinContainer {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.joinContainer2 {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.joinButton {
  width: 300px;
}

.joinField {
  width: 300px;
}

.gmLogoMain {
  width: 200px;
}

.gm_logo_container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
