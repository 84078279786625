.timer {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-family: Arial, sans-serif;
}

.time-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 94px;
}

.time {
  display: block;
  font-size: 28px;
}

.time-label {
  font-size: 24px;
  color: #777;
}
.red-text {
  color: red;
}

.gray-text {
  color: #333;
}
