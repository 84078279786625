.pageContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: auto;
  z-index: 26;
}

.pages {
  color: white;
  max-width: 100vw;
  height: calc(100vh - 56px - 5vh);
  top: 0px;
  font-family: "Arimo", sans-serif;
}
