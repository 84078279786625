.controlGamePaper {
  background-color: #1e1e1e; /* Dark background */
  color: #ffffff; /* Light text */
}

.tabpanel {
  color: #ffffff; /* Make sure tab panel text is readable */
}

.MuiBadge-badge {
  color: #ffffff; /* Badge text color */
}

.MuiTabs-root {
  background-color: #333; /* Darken the background of the tabs */
}

.MuiTab-root {
  color: #ffffff; /* Lighter color for tab text */
}

.MuiTab-root.Mui-selected {
  color: #ffd700; /* Highlight the selected tab */
}

.MuiPaper-root {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Slightly transparent Paper background */
}
