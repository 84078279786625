@keyframes rock {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.rocking-image {
  width: 400px;
  animation: rock 2s infinite;
}
